


















































































































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchItemCreate } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';


@Component
export default class Dashboard extends Vue {
  // my edit
  public plans = ['USDⓢ 穩定方案'];
  public plansCode = ['USDT_stable'];

  public title: string = 'USDⓢ 穩定方案';
  public tokens: string = '';
  public duration: string = '90';
  public contract: boolean = false;
  public agreements: boolean = false;
  public tokenType: string = 'USDT';
  public placeholder: string = 'USDⓢ 數量';
  public agreementsText: string = '我知道此方案承受系統性風險，可能造成大量虧損';

  public valid = false;

  public amountMin = 1000;
  public amountMax = 30000;
  public durationMin = 90;
  public durationMax = 90;

  public titleRules = [
    (v) => !!v || '請選擇方案',
  ];

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public contractURL() {
    if (this.title == "USDⓢ 高利率方案") {
      return "https://drive.google.com/file/d/1SA4Hy2vp1JaJlbbQZq0ZujCsMsZUECH_/view?usp=sharing";
    } else { // USDT 穩定方案
      return "https://drive.google.com/file/d/1SA4Hy2vp1JaJlbbQZq0ZujCsMsZUECH_/view?usp=sharing";
    }
  }

  public amountRules() {
    let minStr;
    let maxStr;

    if (this.tokenType === 'TWD') {
      minStr = '金額需大於 NT$' + this.amountMin;
      maxStr = '金額需小於 NT$' + this.amountMax;
    } else if (this.tokenType === 'USDT') {
      minStr = '金額需大於 ' + this.amountMin + ' USDT';
      maxStr = '金額需小於 ' + this.amountMax + ' USDT';
    } else {
      minStr = '金額需大於 ' + this.amountMin;
      maxStr = '金額需小於 ' + this.amountMax;
    }

    return [
      (v) => !!v || '請決定金額',
      (v) => ( v && v >= this.amountMin ) || minStr,
      (v) => ( v && v <= this.amountMax ) || maxStr,
    ];
  }

  public durationRules() {
    return [
      (v) => !!v || '請決定約期',
      (v) => ( v && v >= this.durationMin ) || '約期需大於 ' + this.durationMin + ' 天',
      (v) => ( v && v <= this.durationMax ) || '約期需小於 ' + this.durationMax + ' 天',
    ];
  }

  public contractRules() {
    return [v => !!v || '請閱讀並同意定型化契約書']
  }

  public agreementRultes() {
    return [v => !!v || '請勾選並同意']
  }

  public get itemCreateError() {
    return readItemCreateError(this.$store);
  }

  public changedValue(value) {
    
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      let submitTitle; // string
      let submitCoin; // string, e.g. 'USDT:3000'
      let submitDuration; // int
      let submitAmount;
      let submitTokenType;

      // parse title and coin
      if (this.title === this.plans[0]) {
          submitTitle = this.plansCode[0]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = parseFloat(this.tokens);
          submitTokenType = 'USDT';
      } else {
          submitTitle = 'undefined_plan_title';
          // submitCoin = 'UNK:' + this.tokens;
          submitAmount = parseFloat(this.tokens);
          submitTokenType = 'UNK';
      }

      // parse duration
      submitDuration = this.duration.replace(' 天', '');
      submitDuration = parseInt(submitDuration, 10);

      // this.dialog = true;
      dispatchItemCreate(this.$store, {title: submitTitle, initial_amount: submitAmount, token_type: submitTokenType, duration: submitDuration});
      
    }
  }

  // parse URL query
  // from /purchase?plan=usdt_stable
  created() {
    let params = (new URL(document.location.href).searchParams);
    let planTitle = params.get("plan");

    for (let i = 0; i < this.plans.length; i++) {
      if (planTitle === this.plansCode[i].toLowerCase()) {
        this.title = this.plans[i];
        break
      }
    }

    this.changedValue(this.title);

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  get userItemsPending() {
    return readUserItems(this.$store);
  }

  async mounted() {
    // await dispatchGetUserItemsPending(this.$store);
    await dispatchGetConstants(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

